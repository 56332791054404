<script>
import InfoPointForm from "@/views/menu/infoPoint/infoPointForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    infoDeskService: "infoDeskService",
  },
})
export default class InfoPointNew extends mixins(InfoPointForm) {
  formId = "new-infoPoint-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.infoDeskService.create({
        idEvent: this.model.idEvent,
        idSpeaker: this.model.idSpeaker,
        code: this.model.code,
        localizations: this.model.localizations,
      });
      this.$router.push("/infopoint/list");
      toastService.success(this.translations.success.infoDesk_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.infoPoint_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      idEvent: null,
      idSpeaker:null,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
